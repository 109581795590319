.service-cards-wrapper{
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 40px auto;
    justify-content: center;
    flex-wrap: wrap;
}

.service-cards-title{
    margin-bottom: 30px;
}

.service-cards-title h2{
    font-weight: normal;
    font-size: 30px;
    text-align: center;
}

.service-cards{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}