.contact-banner-wrapper{
    height: 150px;
    background-color: var(--green);
    color: #fff;
}

.contact-banner-content{
    width: 80%;
    margin: 0 auto;
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    font-size: 30px;
    
}

.contact-banner-button{
    margin-left: 100px;
}

.contact-banner-button button{
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 5px;
    border:  0px;
    cursor: pointer;
    background-color: #fff;
    color: var(--dark-grey);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    -webkit-filter: drop-shadow(0 2px 10p rgba(0, 0, 0, 0.4));
    filter: drop-shadow(0 2px 10p rgba(0, 0, 0, 0.4));
}


.contact-banner-button button:hover{
    opacity: 0.9;
}



@media screen and (max-width: 960px) {

    .contact-banner-wrapper{
        height: 200px;
    }
    .contact-banner-content{
        width: 80%;
        font-size: 30px;
        flex-direction: column;
        text-align: center;
    }

    .contact-banner-text{
        font-size: 25px;
    }

    .contact-banner-button{
        margin: 20px auto;
    }

    .contact-banner-button button{
        padding: 5px 30px;
        font-size: 20px;
        border-radius: 5px;
    }

}
