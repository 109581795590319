.client-banner-wrapper{
    background-color: #fff;
    color: var(--dark-grey);
    border-top: 2px solid var(--light-grey);

}

.client-banner-content{
    width: 80%;
    margin: 0 auto;
    padding: 30px 0px;
    justify-content: center;
    vertical-align: middle;
    font-size: 30px;
}

.client-banner-title{
    margin-bottom: 30px;
}

.client-banner-title h2{
    font-weight: normal;
    font-size: 30px;
    text-align: center;
}

.client-logos-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
}

.client-logo{
    background: var(--coral);
    padding: 5px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction:column;
    margin: 10px 20px ;
    vertical-align: middle;
    width: 250px;
    min-height: 80px
}

.client-logo img{
    vertical-align: middle;
    margin: auto 0px;
}

