.hero-container{
    background: center center/cover no-repeat;
    width: 100%;
    justify-content: center;
    align-items: left;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: var(--green);
}

.hero-content{
    width: 80%;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    padding: 80px 0px;
}

.hero-text{
    width: 70%;
}

.hero-text > h1{
    color: #fff;
    font-size: 50px;
    margin-top: -10px;
    font-weight: normal;
}

.hero-text > p{
    margin-top: 8px;
    color: #fff;
    font-size:20px;
}

.hero-graphics{
    width:20%;
    padding: 30px 0px;
    text-align: center;
    color: #fff;
}

.fa-terminal{
    font-size: 80px;    
    margin-left: 100px;
    animation-name: slide-in;
    animation-duration: 2s;
}

@keyframes slide-in {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}


@media screen and (max-width: 960px) {
    .hero-text{
        width: 100%;
    }
    .hero-text > h1{
        font-size: 40px;
        margin-top: -20px;
    }

    .hero-text > p{
        font-size: 15px;
    }

    .hero-graphics{
        display:none;
    }

}
