.notfound-wrapper{
    width: 80%;
    margin: 80px auto;
    text-align: center;
}

.notfound-image img{
    width: 200px;
    margin: 0 auto;
}

.notfound-text{
    margin-top: 20px;
    font-size: 30px;
    color: var(--coral);
}

@media screen and (max-width: 1250px) {
    .notfound-wrapper{
        margin: 60px auto;
    }
    
    .notfound-text{
        font-size: 20px;
    }
    
}