.howcard-wrapper{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 10px 1.5rem ;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.195);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    min-width:300px;
    max-width: 450px;
    background: #fff;
    padding: 10px;
    animation-name: fade-in;
    animation-duration: 1s;
}

.howcard-title{
    border-radius: 5px 5px 0px 0px;
    background-color: fff;
    padding:15px;
    text-align: center;
    color: var(--coral);
    font-size: 20px;
}

.howcard-title h3{
    font-weight: normal;
}

.howcard-text{
    margin-bottom: 20px;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}


@media screen and (max-width: 960px) {
    .howcard-wrapper{
        padding: 20px;
    }

}
