.contact-wrapper{
    display:flex;
    flex-direction: row;
    width:80%;
    margin: 40px auto;
    flex-wrap:wrap;
    background-color: #fff;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    -webkit-filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2));
}

.contact-form{
    width: 50%;
    min-width: 150px;
}

.contact-details{
    width: 50%;
    padding-top:30px;
    font-size: 18px;
}

.contact-details a{
    text-decoration:none;
    color: var(--dark-grey);
}

.contact-details a:hover{
    text-decoration:none;
    color: var(--coral);
    opacity: 0.8;
    cursor: pointer;
}

.contact-details-icon{
    font-size: 30px;
    color: #000;
}


@media screen and (max-width: 820px) {
    .contact-form{
        width: 100%;
        margin-bottom: 20px;
    }

    .contact-wrapper{
        width:90%;
    }

  }