.footer-container {
  background-color: #fff;
  padding: 0.5rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Social Icons */
.footer-social-icon-link {
  color: var(--dark-grey);
  font-size: 24px;
}

.footer-social-icon-link:hover {
  color: var(--coral);
  opacity: 0.8;
  cursor: pointer;
}

.footer-text {
  max-width: 1000px;
  width: 100%;
}

.footer-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 0 auto;
}

.footer-social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60px;
}

.footer-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer-logo:hover{
  opacity: 0.8;
}

.footer-logo img {
  height: 40px;
  margin-bottom: 5px;
}

.footer-website-rights {
  color: var(--dark-grey);
  margin-bottom: 0px;
  text-align: center;
  font-size: 15px;
}

.footer-website-rights a:visited, .footer-website-rights a:link{
  text-decoration:none;
  color: var(--dark-grey);
}

.footer-website-rights a:hover{
  color: var(--coral);
}

.footer-sf-logo img {
    height: 40px;
}

.footer-sf-logo:hover{
    opacity: 0.8;
}

@media screen and (max-width: 960px) {
  .footer-text {
    width: 80%;
  }
}
