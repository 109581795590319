.service-section-wrapper{
    width: 80%;
    margin: 40px auto;
    border-radius: 8px;
    border:0px;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
    -webkit-filter: drop-shadow(0 0px 5px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 0px 5px rgba(0, 0, 0, 0.1));
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 200px;
    background-color: #fff;
    color: var(--dark-grey);
    min-width: 300px;
    animation-name: fade-in;
    animation-duration: 1s;
}

.service-section-image{
    width: 30%;
    padding: 50px;
    margin: auto;
    display: block;
    min-width: 300px;
}

.service-section-image img{
    width: 100%;
 
}

.service-section-content{
    width: 70%;
    padding: 70px 30px;
}


.service-section-title{
    margin-bottom: 20px;
}

.service-section-title h2{
    font-weight: normal;
}

.service-section-text{
    white-space: pre-line;
}

@media screen and (max-width: 1250px) {
    .service-section-content{
        width: 100%;
        padding-top: 0px;
    }
    
}
