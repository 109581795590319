
.contact-form-label{
    display: block;
    margin-right: 20px;
    margin-bottom: 5px;
}

.contact-form-input{
    display: block;
    padding: 10px;
    margin-bottom: 30px;
    width: 80%;
    border-radius: 5px;
    border:0px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
}

.contact-form-message{
    display: block;
    padding: 10px;
    margin-bottom: 30px;
    width: 80%;
    border-radius: 5px;
    height: 100px;
    border:0px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
}

.contact-form-button{
    padding: 10px 30px;
    font-size: 15px;
    border-radius: 5px;
    border:0px;
    background-color: var(--coral);
    color: var(--light-grey);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    -webkit-filter: drop-shadow(0 2px 10p rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 2px 10p rgba(0, 0, 0, 0.2));
}

.contact-form-button:hover{
    opacity: 0.8;
    cursor: pointer;
}

@media screen and (max-width: 820px) {
    .contact-form-input{
        display: block;
        padding: 10px;
        margin-bottom: 20px;
        width: 100%
    }

    .contact-form-message{
        display: block;
        padding: 10px;
        margin-bottom: 20px;
        width: 100%
    }
  }