.card-wrapper{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 10px 1.5rem ;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.195);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    min-width:300px;
    max-width: 450px;
    background: #fff;
    padding: 10px;
    animation-name: fade-in;
    animation-duration: 1s;
}

.card-wrapper:hover{
    cursor: pointer;
    opacity: 0.8;
}

.card-image img{
    max-height:100%;
    max-width:100%;
    padding: 40px 100px 0px 100px;

}

.card-title{
    border-radius: 5px 5px 0px 0px;
    background-color: fff;
    padding:15px;
    text-align: center;
    color: var(--coral);
    font-size: 20px;
}

.card-title h3{
    font-weight: normal;
}


@media screen and (max-width: 960px) {
    .card-wrapper{
        padding: 40px;
    }
    
    .card-image img{
        padding: 40px 50px 20px 50px;
    }

}
